@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

*{
    font-family: 'Manrope', sans-serif;
}
select, input{
    @apply border-borderPrimary border-solid border-[1px] rounded-lg p-2 outline-none;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background: url("arrow.png") no-repeat right #FFF;
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 17px;
}
select.calendar {
    background: url("calendar-icon.png") no-repeat right #FFF;
    background-position-x: 95%;
}
select[disabled]{
    @apply opacity-30;
}